@use 'styles/config' as *;

.figure {
  @include reset-all;
  display: block;
  position: relative;

  &__caption {
    position: relative;
    bottom: 0;
    left: 0;
    max-height: 50%;

    @media (min-width: $min-720) {
      position: absolute;
      max-width: 80%;
    }
  }
}

.caption {
  @include render-copy(1.2rem, 1.2rem);
  display: flex;
  padding: 1.3rem 2.7rem 1.4rem 1.4rem;
  margin-top: -1.3rem;
  margin-bottom: -1.3rem;
  // text-transform: uppercase;
  background-color: #fff;
  transition: background-color $transition-background;

  :global(body.brand) & {
    @include brandColor(background-color);
  }

  :global(body.dark) & {
    background-color: $color-dark;
  }

  svg {
    flex: 0 0 auto;
    display: block;
    align-self: flex-start;
    margin-right: 1.4rem;
    margin-top: 0.2rem;
  }
}
