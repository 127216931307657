@use 'styles/config' as *;

.tagline {
  display: flex;
  align-items: center;
  margin: 0 0 2rem;

  @media (min-width: $min-720) {
    margin: 0 0 4rem;
  }

  &.below {
    margin: 1rem 0 0;

    @media (min-width: $min-720) {
      margin: 2rem 0 0;
    }
  }

  &__index {
    @include tagline;

    margin-right: 2rem;
  }

  &__text {
    @include tagline;

    .line & {
      @include taglineWithLine;
    }

    .line.long & {
      @include taglineWithLine(7.7rem);
    }

    :global(body.dark) & {
      color: #fff;
    }
  }
}
