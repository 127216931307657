@use 'styles/config' as *;
@use "sass:math";

.button {
  @include reset-button();
  @include reset-anchor();
  max-width: 92vw;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;

  font-size: responsive(1.3rem, 1.6rem);
  font-weight: 600;
  color: $color-font;
  text-transform: uppercase;
  letter-spacing: math.div(0.89, 16) * 1em;
  white-space: nowrap;

  cursor: pointer;

  background: brand();

  box-shadow: 0 4.8rem 3.2rem 0 rgba(#000, 0.1);

  transition-property: background-color, color;
  transition-duration: $transition-enter;

  @media (min-width: $min-1080) {
    box-shadow: 0 6rem 4rem 0 rgba(#000, 0.1);
  }

  svg {
    display: block;

    transition-property: fill;
    transition-duration: $transition-enter;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8rem;
    min-width: 8rem;
    line-height: 8rem;
    transition-property: padding;
    transition-duration: $transition-enter;

    @media (min-width: $min-1080) {
      height: 10rem;
      min-width: 10rem;
      line-height: 10rem;
    }
  }

  &.hasText &__content {
    padding-left: 2.75em;
    padding-right: 0.8em;

    @media (min-width: $min-720) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.hasText.mega &__content {
    padding-left: 0;
    padding-right: 0;
  }

  &.hasText:is(:hover, :focus) &__content {
    @media (min-width: $min-720) {
      padding-left: 3.75em;
      padding-right: 1.875em;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    width: 5em;
  }

  &__text {
    // flex: 1 1 auto;
    display: block;
    overflow: hidden;
    height: 100%;

    @media (min-width: $min-720) {
      width: 0;
    }

    .mega & {
      width: 0;
    }

    &Wrap {
      position: relative;
      display: inline-block;
      white-space: nowrap;
      padding-right: 0.25em;

      @media (max-width: $max-720) {
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }

      @media (min-width: $min-720) {
        padding-right: 1.25em;
      }
    }
  }

  &.chevron svg {
    width: 1.1rem;
    height: 1.8rem;
  }

  &.chevron.hasText svg {
    height: 1.125em;
  }

  &.plus svg {
    width: 2.2rem;
    height: 2.2rem;
  }

  &.close svg {
    width: 2.2rem;
    height: 2.2rem;

    transform: rotate(45deg);
  }

  &.mail svg {
    width: 3.1rem;
    height: 2.1rem;
  }

  &.brand {
    @include brandColor;
    color: $color-dark;

    svg {
      fill: $color-dark;
    }

    &:hover,
    &:focus {
      @include brandColorOverride(background, $color-dark);
      color: #fff;

      svg {
        fill: #fff;
      }

      :global(body.dark) & {
        @include brandColorOverride(background, #fff);
        @include brandColorOverride(color, $color-dark);

        svg {
          @include brandColorOverride(fill, $color-dark);
        }
      }
    }

    :global(.almatica) & {
      color: brandFont();

      &:hover {
        background: brandDark();
      }

      svg {
        fill: brandFont();
      }
    }
  }

  &.white.close svg {
    @include brandColorOverride(fill, $color-dark);
  }

  &.white {
    @include brandColor(color);
    background: #fff;

    svg {
      @include brandColor(fill);
    }

    &:hover,
    &:focus {
      @include brandColorOverride(color, #fff);
      background: $color-dark;

      :global(.adalvo) & {
        background: brandDark();
      }

      :global(.almatica) & {
        color: brandFont();
        background: brandDark();
      }

      svg {
        @include brandColorOverride(fill, #fff);
      }

      :global(body.dark) & {
        @include brandColor(background);
        @include brandColorOverride(color, $color-dark);

        svg {
          @include brandColorOverride(fill, $color-dark);
        }
      }
    }
  }

  &.dark {
    color: #fff;
    background: $color-dark;

    svg {
      fill: #fff;
    }

    &:hover,
    &:focus {
      @include brandColor(background);
      color: $color-dark;

      svg {
        fill: $color-dark;
      }

      :global(body.brand) & {
        @include brandColorOverride(background, #fff);
        @include brandColorOverride(color, $color-dark);

        svg {
          @include brandColorOverride(fill, $color-dark);
        }
      }
    }

    :global(.almatica) & {
      color: brandFont();
      background: brandDark();

      &:hover {
        background: $color-dark;
      }

      svg {
        fill: brandFont();
      }
    }
  }

  &.mega {
    @include brandColor(color);
    background: #fff;

    svg {
      @include brandColor(fill);
    }

    &:hover,
    &:focus {
      @include brandColorOverride(color, $color-dark);

      svg {
        @include brandColorOverride(fill, $color-dark);
      }
    }
  }

  &.hoverBrand {
    &,
    :global(body.brand) &,
    :global(body.white) &,
    :global(body.dark) & {
      &:hover,
      &:focus {
        @include brandColor(background);
        @include brandColorOverride(color, $color-dark);

        svg {
          @include brandColorOverride(fill, $color-dark);
        }
      }
    }
  }

  &.hoverWhite {
    &,
    :global(body.brand) &,
    :global(body.white) &,
    :global(body.dark) & {
      &:hover,
      &:focus {
        @include brandColorOverride(background, #fff);
        @include brandColorOverride(color, $color-dark);

        svg {
          @include brandColorOverride(fill, $color-dark);
        }
      }
    }
  }

  &.hoverDark {
    &,
    :global(body.brand) &,
    :global(body.white) &,
    :global(body.dark) & {
      &:hover,
      &:focus {
        @include brandColorOverride(background, $color-dark);
        @include brandColorOverride(color, #fff);

        svg {
          @include brandColorOverride(fill, #fff);
        }
      }
    }
  }

  // &:hover,
  // &:focus {
  //   color: #fff;
  //   @include brandColor(background);
  // }
}
